<i18n>
{
  "de": {
    "exportButton": "JSON-Export ohne GIS-Daten",
    "exportButtonWithGis": "JSON-Export mit GIS-Daten"
  }
}
</i18n>
<template>
  <button-wrapper>
    <button v-if="portfolio" class="button" @click="onExport">{{ $t('exportButton') }}</button>
    <button v-if="portfolio" class="button" @click="onExportWithGis">{{ $t('exportButtonWithGis') }}</button>
  </button-wrapper>
</template>
<script>

import exportService from '@/services/export-service'
import ButtonWrapper from '@/components/shared/ButtonWrapper'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  components: {
    ButtonWrapper
  },
  methods: {
    onExport () {
      exportService.exportPortfolioJson(this.portfolio, false)
    },
    onExportWithGis () {
      exportService.exportPortfolioJson(this.portfolio, true)
    }
  }
}
</script>
