<i18n>
{
  "de": {
    "portfolioUsersTitle": "Zugangsrechte",
    "portfolioUsersButton": "Benutzer/-innen verwalten",
    "updateNamePendingMessage": "Portfolioname wird gespeichert...",
    "editButtonLabel": "Portfolioname ändern",
    "saveButtonLabel": "Speichern",
    "cancelButtonLabel": "Abbrechen",
    "manageBuildings": "Liegenschaften verwalten",
    "portfolioSettings": "Einstellungen",
    "energyCarrierSettings": "Energieträger",
    "heatingTypesSettings": "Heizungstypen",
    "investmentCostsSettings": "Kostenkennzahlen",
    "lifespansSettings": "Lebensdauer",
    "exportTitle": "Export"
  }
}
</i18n>

<template>
  <main-layout :ready="portfolioReady && portfolioUsersReady" :portfolio="portfolio" class="admin-portfolio-page">
    <template #default>
      <p v-if="updateNameError">{{ updateNameError }}</p>
      <p v-else-if="updateNamePending">{{ $t('updateNamePendingMessage') }}</p>
      <template v-else>
        <template v-if="editName">
          <form class="portfolio-name" @keydown.esc="onCancel">
            <text-input :min-length="1" v-model="name" :edit="true" />
            <button-wrapper>
              <button class="button" type="submit" :disabled="submitDisabled" @click.prevent="onNameSave">{{ $t('saveButtonLabel') }}</button>
              <button class="button" @click.prevent="onCancel">{{ $t('cancelButtonLabel') }}</button>
            </button-wrapper>
          </form>
        </template>
        <template v-else>
          <h1>{{ portfolio.name }}</h1>
          <button-wrapper>
            <div>
              <button class="button" @click.prevent="onEdit">{{ $t('editButtonLabel') }}</button>
              <router-link class="button" :to="{ name: 'adminPortfolioBuildings', params: { portfolio_id: portfolio.id }}">{{ $t('manageBuildings') }}</router-link>
              <router-link class="button" :to="{ name: 'adminPortfolioSettings', params: { portfolio_id: portfolio.id }}">{{ $t('portfolioSettings') }}</router-link>
            </div>
          </button-wrapper>

          <h2>{{ $t('portfolioUsersTitle') }}</h2>
          <ul v-if="hasUsers" class="users-list" :class="usersListClasses">
            <portfolio-user-row class="user-list-row" :user="user" v-for="user in users" :key="user.id" />
          </ul>
          <button-wrapper>
            <router-link class="button" :to="{ name: 'adminPortfolioUsers', params: { portfolio_id: portfolio.id }}">{{ $t('portfolioUsersButton') }}</router-link>
          </button-wrapper>

          <h2>{{ $t('exportTitle') }}</h2>
          <button-wrapper>
            <export-portfolio :portfolio="portfolio" />
          </button-wrapper>
        </template>
      </template>
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin'
import PortfolioUsersMixin from '@/pages/vuex-mixins/PortfolioUsersMixin'

import ButtonWrapper from '@/components/shared/ButtonWrapper'
import TextInput from '@/components/shared/forms/TextInput'
import ExportPortfolio from '@/components/admin-portfolios/ExportPortfolio'
import PortfolioUserRow from '@/components/admin-portfolios/PortfolioUserRow'

import { mapActions } from 'vuex'

export default {
  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
    PortfolioUsersMixin // Provides: portfolio.users, portfolioUsersLoading, portfolioUsersReady
  ],
  data () {
    return {
      name: null,
      editName: false,
      updateNameError: null,
      updateNamePending: false
    }
  },
  computed: {
    users () {
      return this.portfolio && this.portfolio.users
    },
    hasUsers () {
      return Boolean(this.users && this.users.length)
    },
    usersListClasses () {
      return {
        'with-admin-column': this.hasAdmins
      }
    },
    submitDisabled () {
      return this.name === null || this.name.length < 1
    }
  },
  watch: {
    portfolio (val, oldVal) {
      const { name } = val || {}
      this.name = name
    }
  },
  methods: {
    ...mapActions({
      loadUsersById: 'portfolio/loadUsersById',
      updatePortfolioName: 'portfolio/updatePortfolioName'
    }),
    onCancel () {
      this.editName = false
      this.name = ''
    },
    async onNameSave () {
      try {
        this.updateNamePending = true
        await this.updatePortfolioName({ id: this.portfolio.id, name: this.name })
      } catch (error) {
        this.updateNameError = error
      }
      this.updateNamePending = false
      this.editName = false
      this.name = ''
    },
    onEdit () {
      this.name = this.portfolio && this.portfolio.name
      this.editName = true
    }
  },
  components: {
    MainLayout,
    ButtonWrapper,
    TextInput,
    ExportPortfolio,
    PortfolioUserRow
  }
}
</script>

<style>
.admin-portfolio-page .portfolio-name {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: baseline;
  justify-content: start;
  gap: var(--spacing-l);
  row-gap: var(--spacing-s);
  margin-bottom: var(--spacing-m);
}

.admin-portfolio-page .portfolio-name h1 {
  margin-bottom: 0;
}

.admin-portfolio-page .users-list {
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: var(--spacing-l);
  row-gap: var(--spacing-s);
  justify-content: start;
  justify-items: start;
  align-items: baseline;
}

.admin-portfolio-page .users-list.with-admin-column {
  grid-template-columns: repeat(5, auto);
}

.admin-portfolio-page .users-list .portfolio-user-row {
  display: contents;
}
</style>
